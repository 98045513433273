<template>
    <div class="d-flex align-items-center border-bottom mb-3 pb-2 mt-3">

        <div class="flex-grow-1">
            <div class="mt-2 mb-3">
                <!-- Заголовок для секції з загальними параметрами -->
                <h5 class="card-title mb-0"><b>{{ $t('Обладнання тестування') }}</b></h5>
            </div>
        </div>

        <div class="flex-shrink-0">
            <div class="text-muted">
                <!-- Кнопка для видалення блоку -->
                <button 
                    type="button" 
                    class="btn btn-danger btn-icon waves-effect waves-light" 
                    @click="removeItem"
                >
                    <i class="ri-delete-bin-5-line"></i>
                </button>
            </div>
        </div>

    </div>
    <b-row>
        <b-col lg="3">
            <h4 class="fs-15">{{ $t('Оберіть тип пристрою') }}</h4>
            <!-- <input type="text" class="form-control" id="text" v-model="form.ticketName" :placeholder="$t('Оберіть ОС')"> -->
            <b-form-select v-model="selectedOS" :options="listOS" ></b-form-select>
        </b-col>
    </b-row>
</template>

<script>
export default {
    props: ['type', 'activeBlocks'],
    data(){
        return {
            form: '',
            selectTypeDevice: '',
            listTypeDevices: [
                { "value": null, "text": 'Оберіть тип пристрою', disabled: true },
                { "value": "printer", "text": "Принтер"},
                { "value": "printer", "text": "Принтер етикеток"},
                { "value": "printer", "text": "Фіскальний реєстратор"},
                { "value": "printer", "text": "Банківський термінал"},
                { "value": "printer", "text": "Ваги"},
            ],
            selectManufacturer: '',
            listManufacturer: [
                { "value": null, "text": 'Оберіть тип пристрою', disabled: true },
                { "value": "printer", "text": "Принтер"},
            ]
        }
    },
    methods: {
        removeItem(){
            // Метод для видалення поточного блоку
            this.activeBlocks.forEach(element => {
                if(element.type == this.type ){
                    element.status = false;
                    this.$emit('saveBlock', this.activeBlocks)
                }
            });
        }
    }
}
</script>