<template>
    <!-- Блок налаштувань з загальними параметрами -->

    <div class="d-flex align-items-center border-bottom mb-3 pb-2">

        <div class="flex-grow-1">
            <div class="mt-2 mb-3">
                <!-- Заголовок для секції з загальними параметрами -->
                <h5 class="card-title mb-0"><b>{{ $t('Загальні параметри') }}</b></h5>
            </div>
        </div>

        <div class="flex-shrink-0">
            <div class="text-muted">
               <!-- Кнопка для видалення блоку -->
               <button 
                    type="button" 
                    class="btn btn-danger btn-icon waves-effect waves-light" 
                    @click="removeItem"
                >
                    <i class="ri-delete-bin-5-line"></i>
                </button>
            </div>
        </div>

    </div>

    <!-- Рядок з вибором опцій -->
    <b-row>     

        <!-- Блок для вибору операційної системи -->
        <b-col lg="3">
            <h4 class="fs-15">{{ $t('Оберіть ОС') }}</h4>
            <b-form-select v-model="listPlatform[0].selectedOS" :options="listOS" ></b-form-select>
        </b-col>

        <!-- Блоки для вибору версій ОС в залежності від обраної операційної системи -->
        <b-col lg="3" v-if="listPlatform[0].selectedOS == 'windows'">
            <h4 class="fs-15">{{ $t('Оберіть версію ОС') }}</h4>
            <b-form-select v-model="listPlatform[0].selectedVerOS" :options="listVerionsWindows" ></b-form-select>
        </b-col>

        <b-col lg="3" v-if="listPlatform[0].selectedOS == 'android'">
            <h4 class="fs-15">{{ $t('Оберіть версію ОС') }}</h4>
            <b-form-select v-model="listPlatform[0].selectedVerOS" :options="listVerionsAndroid" ></b-form-select>
        </b-col>
        <b-col lg="3" v-if="listPlatform[0].selectedOS == 'macos'">
            <h4 class="fs-15">{{ $t('Оберіть версію ОС') }}</h4>
            <b-form-select v-model="listPlatform[0].selectedVerOS" :options="listVerionsMacOS" ></b-form-select>
        </b-col>
        <b-col lg="3" v-if="listPlatform[0].selectedOS == 'ios'">
            <h4 class="fs-15">{{ $t('Оберіть версію ОС') }}</h4>
            <b-form-select v-model="listPlatform[0].selectedVerOS" :options="listVerionsIOS" ></b-form-select>
        </b-col>

        <!-- Блок для вибору застосунку для тестування -->
        <b-col lg="3">
            <h4 class="fs-15">{{ $t('Застосунок для тестування') }}</h4>
            <b-form-select v-model="listPlatform[0].selectedApp" :options="listApp" ></b-form-select>
        </b-col>

        <!-- Блок для вибору браузера, якщо обрано застосунок "Браузер" -->
        <b-col lg="3" v-if="listPlatform[0].selectedApp == 'browser'">
            <h4 class="fs-15">{{ $t('Оберіть браузер') }}</h4>
            <b-form-select v-model="listPlatform[0].selectedBrowser" :options="listBrowser" ></b-form-select>
        </b-col>

        <!-- Блок для вибору версії застосунку або браузера, залежно від умов -->
        <b-col lg="3" v-if="listPlatform[0].selectedApp == 'appskyservice' || (listPlatform[0].selectedApp == 'browser' && listPlatform[0].selectedBrowser == 'chrome')">
            <h4 class="fs-15">{{ listPlatform[0].selectedApp == 'appskyservice' ? $t('Версія застосунку') : $t('Версію браузеру') }}</h4>

            <!-- Вибір версії застосунку для Windows -->
            <b-form-select 
                v-model="listPlatform[0].selectedVesAppBrow" 
                v-if="listPlatform[0].selectedApp == 'appskyservice' && listPlatform[0].selectedOS == 'windows'" 
                :options="listVerAppSkyWindows" 
            ></b-form-select>
            
            <!-- Вибір версії застосунку для Android -->
            <b-form-select 
                v-model="listPlatform[0].selectedVesAppBrow" 
                v-if="listPlatform[0].selectedApp == 'appskyservice' && listPlatform[0].selectedOS == 'android'" 
                :options="listVerAppSkyAndroid" 
            ></b-form-select>

            <!-- Вибір версії застосунку для IOS -->
            <b-form-select 
                v-model="listPlatform[0].selectedVesAppBrow" 
                v-if="listPlatform[0].selectedApp == 'appskyservice' && (listPlatform[0].selectedOS == 'ios' || listPlatform[0].selectedOS == 'macos')" 
                :options="listVerAppSkyIOS" 
            ></b-form-select>

            <!-- Вибір версії застосунку для Браузера -->
            <b-form-select 
                v-model="listPlatform[0].selectedVesAppBrow" 
                v-if="listPlatform[0].selectedApp == 'browser' && listPlatform[0].selectedBrowser == 'chrome'" 
                :options="listVerChrome" 
            ></b-form-select>
            <!-- <b-form-select v-model="selectedVesAppBrow" v-if="selectedApp == 'appskyservice'" :options="listVerAppSky" ></b-form-select>
            <b-form-select v-model="selectedVesAppBrow" v-if="selectedApp == 'appskyservice'" :options="listVerAppSky" ></b-form-select> -->
        </b-col>
        
    </b-row>

    <button 
        @click="addPlatform()"
        type="button"
        class="btn btn-outline-info btn-icon waves-effect waves-light mt-2"
    >
        <i class="ri-add-line"></i>
    </button>

    <template v-for="(item, index) in listPlatform" :key="index">
        <b-row class="mt-3" v-if="index > 0" >     

            <!-- Блок для вибору операційної системи -->
            <b-col lg="3">
                <h4 class="fs-15">{{ $t('Оберіть ОС') }}</h4>
                <b-form-select v-model="item.selectedOS" :options="listOS" ></b-form-select>
            </b-col>

            <!-- Блоки для вибору версій ОС в залежності від обраної операційної системи -->
            <b-col lg="3" v-if="item.selectedOS == 'windows'">
                <h4 class="fs-15">{{ $t('Оберіть версію ОС') }}</h4>
                <b-form-select v-model="item.selectedVerOS" :options="listVerionsWindows" ></b-form-select>
            </b-col>

            <b-col lg="3" v-if="item.selectedOS == 'android'">
                <h4 class="fs-15">{{ $t('Оберіть версію ОС') }}</h4>
                <b-form-select v-model="item.selectedVerOS" :options="listVerionsAndroid" ></b-form-select>
            </b-col>
            <b-col lg="3" v-if="item.selectedOS == 'macos'">
                <h4 class="fs-15">{{ $t('Оберіть версію ОС') }}</h4>
                <b-form-select v-model="item.selectedVerOS" :options="listVerionsMacOS" ></b-form-select>
            </b-col>
            <b-col lg="3" v-if="item.selectedOS == 'ios'">
                <h4 class="fs-15">{{ $t('Оберіть версію ОС') }}</h4>
                <b-form-select v-model="item.selectedVerOS" :options="listVerionsIOS" ></b-form-select>
            </b-col>

            <!-- Блок для вибору застосунку для тестування -->
            <b-col lg="3">
                <h4 class="fs-15">{{ $t('Застосунок для тестування') }}</h4>
                <b-form-select v-model="item.selectedApp" :options="listApp" ></b-form-select>
            </b-col>

            <!-- Блок для вибору браузера, якщо обрано застосунок "Браузер" -->
            <b-col lg="3" v-if="item.selectedApp == 'browser'">
                <h4 class="fs-15">{{ $t('Оберіть браузер') }}</h4>
                <b-form-select v-model="item.selectedBrowser" :options="listBrowser" ></b-form-select>
            </b-col>

            <!-- Блок для вибору версії застосунку або браузера, залежно від умов -->
            <b-col lg="3" v-if="item.selectedApp == 'appskyservice' || (item.selectedApp == 'browser' && item.selectedBrowser == 'chrome')">
                <h4 class="fs-15">{{ selectedApp == 'appskyservice' ? $t('Версія застосунку') : $t('Версію браузеру') }}</h4>

                <!-- Вибір версії застосунку для Windows -->
                <b-form-select 
                    v-model="item.selectedVesAppBrow" 
                    v-if="item.selectedApp == 'appskyservice' && item.selectedOS == 'windows'" 
                    :options="listVerAppSkyWindows" 
                ></b-form-select>
                
                <!-- Вибір версії застосунку для Android -->
                <b-form-select 
                    v-model="item.selectedVesAppBrow" 
                    v-if="item.selectedApp == 'appskyservice' && item.selectedOS == 'android'" 
                    :options="listVerAppSkyAndroid" 
                ></b-form-select>

                <!-- Вибір версії застосунку для IOS -->
                <b-form-select 
                    v-model="item.selectedVesAppBrow" 
                    v-if="item.selectedApp == 'appskyservice' && (item.selectedOS == 'ios' || item.selectedOS == 'macos')" 
                    :options="listVerAppSkyIOS" 
                ></b-form-select>

                <!-- Вибір версії застосунку для Браузера -->
                <b-form-select 
                    v-model="form.selectedVesAppBrow" 
                    v-if="item.selectedApp == 'browser' && item.selectedBrowser == 'chrome'" 
                    :options="listVerChrome" 
                ></b-form-select>
                <!-- <b-form-select v-model="selectedVesAppBrow" v-if="selectedApp == 'appskyservice'" :options="listVerAppSky" ></b-form-select>
                <b-form-select v-model="selectedVesAppBrow" v-if="selectedApp == 'appskyservice'" :options="listVerAppSky" ></b-form-select> -->
            </b-col>
        </b-row>
    </template>

</template>

<script>
// import Multiselect from "@vueform/multiselect";
// import "@vueform/multiselect/themes/default.css";

export default {
    props: ['type', 'activeBlocks'],
    components: {
        // Multiselect
    },
    data(){
        return{
            listPlatform: [
                {
                    selectedOS: 'windows',
                    selectedVerOS: '',
                    selectedApp: '',
                    selectedBrowser: '',
                    selectedVesAppBrow: ''
                }
            ],
            form: {
                selectedOS: 'windows',
                selectedVerOS: '',
                selectedApp: '',
                selectedBrowser: '',
                selectedVesAppBrow: ''
            },
            selectedOS: 'windows',
            listOS: [
                { "value": null, "text": 'Оберіть ОС', disabled: true },
                { "value": "windows", "text": "Windows"},
                { "value": "android", "text": "Android"},
                { "value": "ios", "text": "IOS"},
                { "value": "macos", "text": "MacOS"},
            ],
            selectedVerOS: '',
            listVerionsWindows: [
                { "value": null, "text": 'Оберіть версію ОС', disabled: true },
                { "value": "windows11", "text": "Windows 11"},
                { "value": "windows10", "text": "Windows 10"},
                { "value": "windows81", "text": "Windows 8.1"},
                { "value": "windows8", "text": "Windows 8"},
            ],
            listVerionsAndroid: [
                { "value": null, "text": 'Оберіть версію ОС', disabled: true },
                { "value": "14.0", "text": "Android 14.0"},
                { "value": "13.0", "text": "Android 13.0"},
                { "value": "12.1", "text": "Android 12.1"},
                { "value": "12.0", "text": "Android 12.0"},
                { "value": "11.0", "text": "Android 11.0"},
                { "value": "10.0", "text": "Android 10.0"},
                { "value": "9.0", "text": "Android 9.0"},
                { "value": "8.1", "text": "Android 8.1"},
                { "value": "8.0", "text": "Android 8.0"},
                { "value": "7.1", "text": "Android 7.1"},
                { "value": "7.0", "text": "Android 7.0"},
                { "value": "6.0", "text": "Android 6.0"},
                { "value": "5.1", "text": "Android 5.1"},
                { "value": "5.0", "text": "Android 5.0"},
                { "value": "4.4", "text": "Android 4.4"},
            ],
            listVerionsIOS: [
                { "value": null, "text": 'Оберіть версію ОС', disabled: true },
                { "value": "17.2", "text": "IOS 17.2"},
                { "value": "17.1", "text": "IOS 17.1"},
                { "value": "17", "text": "IOS 17"},
                { "value": "16", "text": "IOS 16"},
                { "value": "15", "text": "IOS 15"},
                { "value": "14", "text": "IOS 14"},
                { "value": "13", "text": "IOS 13"},
                { "value": "12", "text": "IOS 12"},
                { "value": "11", "text": "IOS 11"},
                { "value": "10.3.4", "text": "IOS 10.3.4"},
                { "value": "10.3.3", "text": "IOS 10.3.3"},
            ],
            listVerionsMacOS: [
                { "value": null, "text": 'Оберіть версію ОС', disabled: true },
                { "value": "sonoma", "text": "macOS Sonoma"}, 
                { "value": "ventura", "text": "macOS Ventura"}, 
                { "value": "monterey", "text": "macOS Monterey"}, 
                { "value": "bigsur", "text": "macOS Big Sur"}, 
                { "value": "catalina", "text": "macOS Catalina"}, 
                { "value": "mojave", "text": "macOS Mojave"}, 
                { "value": "highsierra", "text": "macOS High Sierra"}, 
            ],
            selectedApp: '',
            listApp: [
                { "value": null, "text": 'Застосунок для тестування', disabled: true },
                { "value": "appskyservice", "text": "Застосунок SkyService"}, 
                { "value": "browser", "text": "Браузер"}, 
            ],
            selectedBrowser: '',
            listBrowser: [
                { "value": null, "text": 'Виберіть браузер', disabled: true },
                { "value": "chrome", "text": "Google Chrome"}, 
                { "value": "safari", "text": "Safari"}, 
                { "value": "edge", "text": "Edge"}, 
                { "value": "firefox", "text": "Mozilla Firefox"}
            ],
            selectedVesAppBrow: '',
            listVerAppSkyWindows: [
                { "value": null, "text": 'Виберіть версію застосунку', disabled: true },
                { "value": "skyservice21", "text": "SkyService 2.1"}, 
                { "value": "skyservice20", "text": "SkyService 2.0"}, 
                { "value": "skyservice19", "text": "SkyService 1.9"}, 
                { "value": "skyservice18", "text": "SkyService 1.8"}, 
                { "value": "skyservice17", "text": "SkyService 1.7"}, 
                { "value": "skyservice16", "text": "SkyService 1.6"}, 
            ],
            listVerAppSkyAndroid: [
                { "value": null, "text": 'Виберіть версію застосунку', disabled: true },
                { "value": "skyservice13.8", "text": "SkyService 13.8"}, 
                { "value": "skyservice13.7", "text": "SkyService 13.7"}, 
                { "value": "skyservice13.6", "text": "SkyService 13.6"}, 
                { "value": "skyservice13.5", "text": "SkyService 13.5"}, 
                { "value": "skyservice13.4", "text": "SkyService 13.4"}, 
                { "value": "skyservice13.3", "text": "SkyService 13.3"}, 
                { "value": "skyservice13.2", "text": "SkyService 13.2"}, 
                { "value": "skyservice13.1", "text": "SkyService 13.1"}, 
                { "value": "skyservice13.0", "text": "SkyService 13.0"}, 
                { "value": "skyservice12.9", "text": "SkyService 12.9"}, 
                { "value": "skyservice12.8", "text": "SkyService 12.8"}, 
                { "value": "skyservice12.7", "text": "SkyService 12.7"}, 
                { "value": "skyservice12.6", "text": "SkyService 12.6"}, 
                { "value": "skyservice12.5", "text": "SkyService 12.5"}, 
                { "value": "skyservice12.4", "text": "SkyService 12.4"}, 
                { "value": "skyservice12.3", "text": "SkyService 12.3"}, 
                { "value": "skyservice12.2", "text": "SkyService 12.2"}, 
                { "value": "skyservice12.1", "text": "SkyService 12.1"}, 
                { "value": "skyservice12.0", "text": "SkyService 12.0"}, 
                { "value": "skyservice11.9", "text": "SkyService 11.9"}, 
                { "value": "skyservice11.8", "text": "SkyService 11.8"}, 
                { "value": "skyservice11.7", "text": "SkyService 11.7"}, 
                { "value": "skyservice11.6", "text": "SkyService 11.6"}, 
                { "value": "skyservice11.5", "text": "SkyService 11.5"}, 
                { "value": "skyservice11.4", "text": "SkyService 11.4"}, 
                { "value": "skyservice11.3", "text": "SkyService 11.3"}, 
                { "value": "skyservice11.2", "text": "SkyService 11.2"}, 
                { "value": "skyservice11.1", "text": "SkyService 11.1"}, 
                { "value": "skyservice11.0", "text": "SkyService 11.0"}, 
            ],
            listVerAppSkyIOS: [
                { "value": null, "text": 'Виберіть версію застосунку', disabled: true },
                { "value": "skyservice5.2", "text": "SkyService 5.2"}, 
                { "value": "skyservice5.1", "text": "SkyService 5.1"}, 
                { "value": "skyservice5.0", "text": "SkyService 5.0"}, 
                { "value": "skyservice4.3", "text": "SkyService 4.3"}, 
                { "value": "skyservice4.2", "text": "SkyService 4.2"}, 
                { "value": "skyservice4.1", "text": "SkyService 4.1"}, 
                { "value": "skyservice4.0", "text": "SkyService 4.0"}, 
                { "value": "skyservice3.2", "text": "SkyService 3.2"}, 
                { "value": "skyservice3.1", "text": "SkyService 3.1"}, 
                { "value": "skyservice3.0", "text": "SkyService 3.0"}, 
            ],
            listVerChrome: [
                { "value": null, "text": 'Виберіть версію браузера', disabled: true },
                { "value": "chrome120", "text": "Google Chrome 120"}, 
                { "value": "chrome119", "text": "Google Chrome 119"}, 
                { "value": "chrome118", "text": "Google Chrome 118"}, 
                { "value": "chrome117", "text": "Google Chrome 117"}, 
                { "value": "chrome116", "text": "Google Chrome 116"}, 
                { "value": "chrome115", "text": "Google Chrome 115"}, 
                { "value": "chrome114", "text": "Google Chrome 114"}, 
                { "value": "chrome113", "text": "Google Chrome 113"}, 
                { "value": "chrome112", "text": "Google Chrome 112"}, 
                { "value": "chrome111", "text": "Google Chrome 111"}, 
                { "value": "chrome110", "text": "Google Chrome 110"}, 
                { "value": "chrome109", "text": "Google Chrome 109"}, 
                { "value": "chrome108", "text": "Google Chrome 108"}, 
                { "value": "chrome107", "text": "Google Chrome 107"}, 
                { "value": "chrome106", "text": "Google Chrome 106"}, 
                { "value": "chrome105", "text": "Google Chrome 105"}, 
                { "value": "chrome104", "text": "Google Chrome 104"}, 
                { "value": "chrome103", "text": "Google Chrome 103"}, 
                { "value": "chrome102", "text": "Google Chrome 102"}, 
                { "value": "chrome101", "text": "Google Chrome 101"}, 
                { "value": "chrome100", "text": "Google Chrome 100"}, 
                { "value": "chrome99", "text": "Google Chrome 99"}, 
                { "value": "chrome98", "text": "Google Chrome 98"}, 
                { "value": "chrome97", "text": "Google Chrome 97"}, 
                { "value": "chrome96", "text": "Google Chrome 96"}, 
                { "value": "chrome95", "text": "Google Chrome 95"}, 
                { "value": "chrome94", "text": "Google Chrome 94"}, 
                { "value": "chrome92", "text": "Google Chrome 92"}, 
                { "value": "chrome85", "text": "Google Chrome 85"}, 
                { "value": "chrome81", "text": "Google Chrome 81"}, 
                { "value": "chrome80", "text": "Google Chrome 80"}, 
            ],
            listPriority: [
                {
                    "label": this.$t('priorityHigh'),
                    "value": "2"
                },
                {
                    "label": this.$t('priorityMedium'),
                    "value": "1"
                },
                {
                    "label": this.$t('priorityLow'),
                    "value": "0"
                }
            ],
            problemEnvironment: [
                {
                    "label": this.$t('App'),
                    "value": "App"
                },
                {
                    "label": 'Inside',
                    "value": "inside"
                },
                {
                    "label": this.$t('NewAdmin_panel'),
                    "value": "newDashboard"
                },
                {
                    "label": this.$t('admin_allappsSkymarket'),
                    "value": "admin_allappsSkymarket"
                },
                {
                    "label": this.$t('admin_allappsSellup'),
                    "value": "admin_allappsSellup"
                },
                {
                    "label": this.$t('admin_allappsHscreen'),
                    "value": "admin_allappsHscreen"
                },
                {
                    "label": this.$t('admin_allappsMono'),
                    "value": "admin_allappsMono"
                },
                {
                    "label": this.$t('admin_allappsPrivat'),
                    "value": "admin_allappsPrivat"
                },
                {
                    "label": this.$t('admin_shake_to_pay'),
                    "value": "admin_shake_to_pay"
                },
                {
                    "label": this.$t('admin_allappsBookBeauty'),
                    "value": "admin_allappsBookBeauty"
                },
                {
                    "label": this.$t('admin_sendcheck'),
                    "value": "admin_sendcheck"
                },
                {
                    "label": this.$t('admin_panel'),
                    "value": "admin_panel"
                },
                {
                    "label": this.$t('dir_pos'),
                    "value": "pos_terminal"
                },
                {
                    "label": this.$t('dir_posnew'),
                    "value": "posnew"
                },
                {
                    "label": this.$t('admin_allProducts'),
                    "value": "admin_allProducts"
                },
                {
                    "label": this.$t('admin_warehouseProducts'),
                    "value": "admin_warehouseProducts"
                },
                {
                    "label": this.$t('admin_services'),
                    "value": "admin_services"
                },
                {
                    "label": this.$t('admin_Onsale'),
                    "value": "admin_Onsale"
                },
                {
                    "label": this.$t('admin_remains'),
                    "value": "admin_remains"
                },
                {
                    "label": this.$t('admin_reportsMain'),
                    "value": "admin_reportsMain"
                },
                {
                    "label": this.$t('admin_reportsSale'),
                    "value": "admin_reportsSale"
                },
                {
                    "label": this.$t('admin_reportsComings'),
                    "value": "admin_reportsComings"
                },
                {
                    "label": this.$t('admin_reportsReturns'),
                    "value": "admin_reportsReturns"
                },
                {
                    "label": this.$t('admin_reportsMove'),
                    "value": "admin_reportsMove"
                },
                {
                    "label": this.$t('admin_reportsManufacture'),
                    "value": "admin_reportsManufacture"
                },
                {
                    "label": this.$t('admin_reportsOpenshifts'),
                    "value": "admin_reportsOpenshifts"
                },
                {
                    "label": this.$t('admin_reportsBonusreports'),
                    "value": "admin_reportsBonusreports"
                },
                {
                    "label": this.$t('admin_reportsGoodsconsumption'),
                    "value": "admin_reportsGoodsconsumption"
                },
                {
                    "label": this.$t('admin_reportsTopsales'),
                    "value": "admin_reportsTopsales"
                },
                {
                    "label": this.$t('admin_reportsABCproducts'),
                    "value": "admin_reportsABCproducts"
                },
                {
                    "label": this.$t('admin_reportsABCcategories'),
                    "value": "admin_reportsABCcategories"
                },
                {
                    "label": this.$t('admin_reportsTopcomings'),
                    "value": "admin_reportsTopcomings"
                },
                {
                    "label": this.$t('admin_reportsTopclients'),
                    "value": "admin_reportsTopclients"
                },
                {
                    "label": this.$t('admin_reportsDays'),
                    "value": "admin_reportsDays"
                },
                {
                    "label": this.$t('admin_reportsDecommissionedgoods'),
                    "value": "admin_reportsDecommissionedgoods"
                },
                {
                    "label": this.$t('admin_reportsGoods'),
                    "value": "admin_reportsGoods"
                },
                {
                    "label": this.$t('admin_reportsTopsales'),
                    "value": "admin_reportsTopsales"
                },
                {
                    "label": this.$t('admin_reportsABCproducts'),
                    "value": "admin_reportsABCproducts"
                },
                {
                    "label": this.$t('admin_reportsABCcategories'),
                    "value": "admin_reportsABCcategories"
                },
                {
                    "label": this.$t('admin_reportsTopcomings'),
                    "value": "admin_reportsTopcomings"
                },
                {
                    "label": this.$t('admin_reportsTopclients'),
                    "value": "admin_reportsTopclients"
                },
                {
                    "label": this.$t('admin_reportsDays'),
                    "value": "admin_reportsDays"
                },
                {
                    "label": this.$t('admin_reportsDecommissionedgoods'),
                    "value": "admin_reportsDecommissionedgoods"
                },
                {
                    "label": this.$t('admin_reportsGoods'),
                    "value": "admin_reportsGoods"
                },
                {
                    "label": this.$t('admin_reportsGoodscategory'),
                    "value": "admin_reportsGoodscategory"
                },
                {
                    "label": this.$t('admin_reportsSalesdynamic'),
                    "value": "admin_reportsSalesdynamic"
                },
                {
                    "label": this.$t('admin_reportsRevenuereport'),
                    "value": "admin_reportsRevenuereport"
                },
                {
                    "label": this.$t('admin_reportsWorkshop'),
                    "value": "admin_reportsWorkshop"
                },
                {
                    "label": this.$t('admin_reportsEmployee'),
                    "value": "admin_reportsEmployee"
                },
                {
                    "label": this.$t('admin_reportsInventory'),
                    "value": "admin_reportsInventory"
                },
                {
                    "label": this.$t('admin_reportsDanger'),
                    "value": "admin_reportsDanger"
                },
                {
                    "label": this.$t('admin_reportsRecommendpurchase'),
                    "value": "admin_reportsRecommendpurchase"
                },
                {
                    "label": this.$t('admin_reportsTaxes'),
                    "value": "admin_reportsTaxes"
                },
                {
                    "label": this.$t('admin_reportsShifts'),
                    "value": "admin_reportsShifts"
                },
                {
                    "label": this.$t('admin_reportsWalletsstatus'),
                    "value": "admin_reportsWalletsstatus"
                },
                {
                    "label": this.$t('admin_reportsFin'),
                    "value": "admin_reportsFin"
                },
                {
                    "label": this.$t('admin_reportsCashboxallmotions'),
                    "value": "admin_reportsCashboxallmotions"
                },
                {
                    "label": this.$t('admin_reportsCashboxinkmotions'),
                    "value": "admin_reportsCashboxinkmotions"
                },
                {
                    "label": this.$t('admin_reportsCashboxoutmotions'),
                    "value": "admin_reportsCashboxoutmotions"
                },
                {
                    "label": this.$t('admin_reportsCashboxsalemotions'),
                    "value": "admin_reportsCashboxsalemotions"
                },
                {
                    "label": this.$t('admin_reportsCashboxinmotions'),
                    "value": "admin_reportsCashboxinmotions"
                },
                {
                    "label": this.$t('admin_reportsWalletallmotions'),
                    "value": "admin_reportsWalletallmotions"
                },
                {
                    "label": this.$t('admin_reportsWalletinmotions'),
                    "value": "admin_reportsWalletinmotions"
                },
                {
                    "label": this.$t('admin_reportsWalletoutmotions'),
                    "value": "admin_reportsWalletoutmotions"
                },
                {
                    "label": this.$t('admin_marketingClients'),
                    "value": "admin_marketingClients"
                },
                {
                    "label": this.$t('admin_marketingOffers'),
                    "value": "admin_marketingOffers"
                },
                {
                    "label": this.$t('admin_marketingDiscount'),
                    "value": "admin_marketingDiscount"
                },
                {
                    "label": this.$t('admin_marketingStock'),
                    "value": "admin_marketingStock"
                },
                {
                    "label": this.$t('admin_marketingBonus'),
                    "value": "admin_marketingBonus"
                },
                {
                    "label": this.$t('admin_settingsglobal'),
                    "value": "admin_settingsglobal"
                },
                {
                    "label": this.$t('admin_settingstradepoints'),
                    "value": "admin_settingstradepoints"
                },
                {
                    "label": this.$t('admin_settingscategory'),
                    "value": "admin_settingscategory"
                },
                {
                    "label": this.$t('admin_settingsexpenses'),
                    "value": "admin_settingsexpenses"
                },
                {
                    "label": this.$t('admin_settingstax'),
                    "value": "admin_settingstax"
                },
                {
                    "label": this.$t('admin_settingsworkshop'),
                    "value": "admin_settingsworkshop"
                },
                {
                    "label": this.$t('admin_settingsnotices'),
                    "value": "admin_settingsnotices"
                },
                {
                    "label": this.$t('admin_settingshalls'),
                    "value": "admin_settingshalls"
                },
                {
                    "label": this.$t('admin_settingstables'),
                    "value": "admin_settingstables"
                },
                {
                    "label": this.$t('admin_settingsdevices'),
                    "value": "admin_settingsdevices"
                },
                {
                    "label": this.$t('admin_settingsusers'),
                    "value": "admin_settingsusers"
                },
                {
                    "label": this.$t('admin_settingsprovider'),
                    "value": "admin_settingsprovider"
                },
                {
                    "label": this.$t('admin_settingswallets'),
                    "value": "admin_settingswallets"
                },
                {
                    "label": this.$t('admin_settingsmybalance'),
                    "value": "admin_settingsmybalance"
                },
                {
                    "label": this.$t('admin_settingspartnersprogram'),
                    "value": "admin_settingspartnersprogram"
                },
                {
                    "label": this.$t('admin_help'),
                    "value": "admin_help"
                }
            ],
        }
    },
    methods: {
        addPlatform(){
            
            var tempForm = {
                selectedOS: 'windows',
                selectedVerOS: '',
                selectedApp: '',
                selectedBrowser: '',
                selectedVesAppBrow: ''
            }
            this.listPlatform.push(tempForm)
            console.log("listp", this.listPlatform);
            // this.form.selectedOS = 'windows';
            // this.form.selectedVerOS ='';
            // this.form.selectedApp ='';
            // this.form.selectedBrowser = '';
            // this.form.selectedVesAppBrow = ''
        },
        removeItem(){
            // Метод для видалення поточного блоку
            this.activeBlocks.forEach(element => {
                if(element.type == this.type ){
                    element.status = false;
                    this.$emit('saveBlock', this.activeBlocks)
                }
            });
        }
    }
}
</script>