<template>
    <div class="d-flex align-items-center border-bottom mb-3 pb-2 mt-3">

        <div class="flex-grow-1">
            <div class="mt-2 mb-3">
                <!-- Заголовок для секції з загальними параметрами -->
                <h5 class="card-title mb-0"><b>{{ $t('Алгоритм дій') }}</b></h5>
            </div>
        </div>

        <div class="flex-shrink-0">
            <div class="text-muted">
                <!-- Кнопка для видалення блоку -->
                <button 
                    type="button" 
                    class="btn btn-danger btn-icon waves-effect waves-light" 
                    @click="removeItem"
                >
                    <i class="ri-delete-bin-5-line"></i>
                </button>
            </div>
        </div>

    </div>
    
    <b-row>
        <b-col lg="12">
            <div class="d-flex mb-3">
                <h6 class="card-title mb-0 flex-grow-1">{{ $t('Вкажіть покроково всі дії') }}</h6>
            </div>
            <div class="flex-grow-1 ms-2 mb-2">
                <div class="input-group">
                    <input type="text" v-model="item.taskName" class="form-control w-50"  >
                    <button @click="add(item)" class="btn btn-success" type="button" ><i class="ri-add-line"></i></button>
                </div>
            </div>
            <ul class="list-unstyled vstack gap-3 mb-0">
                <li v-for="(input, index) in this.form.tasks" :key="index">
                    <div class="d-flex align-items-center">
                        <div class="flex-shrink-0">
                            <div class="avatar-xs flex-shrink-0 me-1"><div class="avatar-title rounded bg-soft-dark text-black">#{{index+1}}</div></div>
                        </div>
                        <div class="flex-grow-1 ms-2">
                            <div class="input-group">
                                <input type="text" class="form-control" v-model="input.taskName" v-on:keyup.enter="add(index)">
                                <button @click="add(input,index)" class="btn btn-success" type="button"><i class="ri-add-line"></i></button>
                                <button @click="remove(index)" class="btn btn-danger" type="button"><i class="ri-delete-bin-2-line"></i></button>
                            </div>
                            <!-- <h6 class="mb-1"><b>{{item.name_report}}</b></h6>
                            <p class="text-muted mb-0">{{item.name_worker}}</p> -->
                        </div>
                    </div>
                </li>
            </ul>
        </b-col>
    </b-row>
</template>

<script>
export default{
    props: ['type', 'activeBlocks'],
    data(){
        return{
            form: {
                tasks: []
            },
            item: {
                taskName: '',
                checked: 0
            },
        }
    },
    methods: {
        add(e, index) {;
            console.log("e", e);
            if (this.form.tasks.length > 0) {
                this.form.tasks.push({
                    taskName: e.taskName,
                    checked: 0
                });
            } else {
                this.form.tasks.splice(index + 1, 0, {
                    taskName: e.taskName,
                    checked: 0
                });
            }
            this.item.taskName = "";
        },
        removeItem(){
            // Метод для видалення поточного блоку
            this.activeBlocks.forEach(element => {
                if(element.type == this.type ){
                    element.status = false;
                    this.$emit('saveBlock', this.activeBlocks)
                }
            });
        }
    }
}
</script>